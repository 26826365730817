
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import rawCountries from 'shared/FormControls/RawCountries';
import moment from 'moment';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};

export const defaultSuperAdmin = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'super_admin',
  photoURL: 'https://via.placeholder.com/150',
};

export const perPage = 500;

// export const lbsValue=2.20462262185;
export const lbsValue = 2.2;


export const initialUrl = '/shipper/quotation/create';
// export const initialUrl ='/super-admin/freight-forwarder/admins/list/view'

export const packgingNames = [
  'Envelope',
  'Pak',
  'Pallet',
  'Skid',
  'Bag',
  'Bale',
  'Barrel',
  'Basket',
  'Bin',
  'Box',
  'Bounch',
  'Bundle',
  'Cabinet',
  'Can',
  'Carboy',
  'Carrier',
  'Carton',
  'Case',
  'Cask',
  'Container',
  'Crate',
  'Cylinder',
  'Drum',
  'Jerrican',
  'Loose',
  'Other',
  'Package',
  'Pail',
  'Pieces',
  'Pipe Line',
  'Reel',
  'Roll',
  'Spool',
  'Tank',
  'Totes',
  'Tube',
  'Unit',
  'Van Pack',
  'Wrapped',
];

export const editQuote = {
  greenColor: '#91d7a2',
  purpleColor: '#c491f0',
  salmonColor: '#ffc6af',
  pinkColor: '#ffb2ff',
  lightBlueColor1: '#cee1f4',
  lightBlueColor2: '#b6d2ee',
  lightBlueColor3: '#9dc3e9',
  lightBlueColor4: '#85b4e4',
  lightBlueColor5: '#77a2cd',
};

export const handleNegativeValue = (event) => {
  const allowedCharacters = /[0-9.,]/;
  const inputChar = String.fromCharCode(event.keyCode || event.which);

  // if (event?.key === '-' || event?.key === '+' || !allowedCharacters.test(inputChar)) {
  if (event.key === '-' || !allowedCharacters.test(inputChar)) {
    event.preventDefault();
  }
};

export const handleNegativeValueAllowPlusAndMinu = (event) => {
  const allowedCharacters = /[0-9.,-]/;
  const inputChar = String.fromCharCode(event.keyCode || event.which);

  if (event?.key === '-0' || !allowedCharacters.test(inputChar)) {
    event.preventDefault();
  }
};

export const handleAmountFormat = (value) => {
  return parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const toastDelay=4500;

export const hazamatClass = [
  '1.1',
  '1.1G',
  '1.2',
  '1.2G',
  '1.3',
  '1.3G',
  '1.4',
  '1.4B',
  '1.4C',
  '14G',
  '1.4S',
  '1.5',
  '1.6',
  '2.1',
  '2.2',
  '2.2(5.1)',
  '2.3',
  '3',
  '3(6.1)',
  '3(8)',
  '4.1',
  '4.2',
  '4.3',
  '4.3(3)(8)',
  '4.3(8)(3)',
  '5.2',
  '5.1(8)',
  '5.1',
  '6.1',
  '6.1(3)',
  '6.1(5.1)',
  '6.1(8)',
  '6.1(8)(3)',
  '6.2',
  '8',
  '8(3)',
  '8(5.1)',
  '8(6.1)',
  '9',
];

export const typeOfGoodNames = [
  'General Cargo', 
  'Dangerous Goods', 
  'Express Cargo',
  'Temperature Controlled Cargo',
  'Perishable Cargo',
  'Oversized Cargo',
];

export const chooseUnitNames = [
  'Imperial / Metric', 
  'Imperial', 
  'Metric',
];

export const packingGroupNames = [
  'I', 
  'II', 
  'III',
  'None',
];

export const chooseShipper = ['Known Shipper', 'Unknown Shipper'];
export const stackableNames = ['Yes', 'No'];
export const hazmatNames = ['Yes', 'No'];
export const screenedNames = ['Yes', 'No'];
export const turnableNames = ['Yes', 'No'];
export const frieghtNames = ['Yes', 'No'];
export const chargeableNames = ['Yes', 'No'];


// Function to get the dialing code for a given country code
export const getDialingCodeFromCountryCode = (countryCode) => {
  let conutryDialCode = '';
      
  rawCountries.forEach(item => {
    if (countryCode === item?.[2]) {
      conutryDialCode = item?.[3] ? `+${item?.[3]}`  : '';
    }
  })
  return conutryDialCode;
};



// // Function to format the phone number with the dialing code
export const formatPhoneNumberWithDialingCode = (mobileNumber) => {
  // const dialingCode = getDialingCodeFromCountryCode(countryCode);
  // console.log(mobileNumber,'mobilenumber')
  const phoneNumberWithDialingCode = `+${mobileNumber}`;
  const formattedPhoneNumber = formatPhoneNumberIntl(phoneNumberWithDialingCode);
  return formattedPhoneNumber;
};

export function formattedDate(date){
  return moment(date, 'MM-DD-YYYY HH:mm:ss').format('YYYY-MM-DD');
}

export const roles = ['All', 'Assigned', 'Unassigned', 'Used', 'Unused', 'Active', 'Suspended', 'Expired'];
