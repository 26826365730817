export const airlineAdminNavConfig = [
  // Settings
  {
    id: 'profileManagement',
    title: 'Settings',
    messageId: 'sidebar.profile',
    type: 'item',
    icon: ['/assets/images/sideMenu/settings-active.png', '/assets/images/sideMenu/settings.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/profile/update'],
    // children: [
    //   {
    //     id: 'profile',
    //     title: 'View Profile',
    //     messageId: 'sidebar.adminProfile.update',
    //     type: 'item',
    //     url: '/airline/admin/profile/update',
    //     icon: '/assets/images/sideMenu/sub-menu.png', 
    //   },
    // ],
  },

  // Freight Forwarders
  {
    id: 'freightForwarders',
    title: 'Freight Forwarders',
    messageId: 'sidebar.freightForwarder',
    type: 'item',
    icon: ['/assets/images/sideMenu/freight-forwarder-active.png', '/assets/images/sideMenu/freight-forwarder.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/freight-forwarder/create',
      '/airline/admin/freight-forwarder/list/view'],
    // children: [
    //   {
    //     id: 'freightForwarderAdd',
    //     title: 'Add',
    //     messageId: 'sidebar.freightForwarderAdd',
    //     type: 'item',
    //     url: '/airline/admin/freight-forwarder/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'freightForwarderList',
    //     title: 'List',
    //     messageId: 'sidebar.freightForwarderList',
    //     type: 'item',
    //     url: '/airline/admin/freight-forwarder/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // History
  // {
  //   id: 'historyManagement',
  //   title: 'History',
  //   messageId: 'sidebar.history',
  //   type: 'item',
  //   icon: ['/assets/images/sideMenu/history-active.png', '/assets/images/sideMenu/history.png'],
  //   role: ['ROLE_AIRLINE_ADMIN'],
  //   url: ['/airline/admin/history/list',
  //     '/airline/admin/history/view/'],
  //   // children: [
  //   //   {
  //   //     id: 'profile',
  //   //     title: 'History List',
  //   //     messageId: 'sidebar.history.list',
  //   //     type: 'item',
  //   //     url: '/airline/admin/history/list',
  //   //     icon: '/assets/images/sideMenu/sub-menu.png',
  //   //   },
  //   // ],
  // },

  // General Rate
  {
    id: 'generalRate',
    title: 'General Rate',
    messageId: 'sidebar.generalRate',
    type: 'item',
    icon: ['/assets/images/sideMenu/contract-rate-active.png', '/assets/images/sideMenu/contract-rate.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/general-rate/create',
      '/airline/admin/general-rate/upload-rates',
      '/airline/admin/general-rate/list/view',
      '/airline/admin/general-rate/edit/',
      '/airline/admin/general-rate/surcharge'],
    // children: [
    //   {
    //     id: 'createGeneralRates',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createGeneralRates',
    //     type: 'item',
    //     url: '/airline/admin/general-rate/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'generalRateList',
    //     title: 'List',
    //     messageId: 'sidebar.airline.generalRateList',
    //     type: 'item',
    //     url: '/airline/admin/general-rate/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Contract Rates
  {
    id: 'priorityRate',
    title: 'Contract Rates',
    messageId: 'sidebar.priorityRate',
    type: 'item',
    icon: ['/assets/images/sideMenu/contract-rate-active.png', '/assets/images/sideMenu/contract-rate.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/contract-rate/create',
      '/airline/admin/contract-rate/upload-rates',
      '/airline/admin/contract-rate/list/view',
      '/airline/admin/contract-rate/edit/'],
    // children: [
    //   {
    //     id: 'createPriorityRates',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createPriorityRates',
    //     type: 'item',
    //     url: '/airline/admin/contract-rate/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'priorityRates',
    //     title: 'List',
    //     messageId: 'sidebar.airline.priorityRates',
    //     type: 'item',
    //     url: '/airline/admin/contract-rate/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Promo Rate
  {
    id: 'spotRate',
    title: 'Promo Rate',
    messageId: 'sidebar.spotRate',
    type: 'item',
    icon: ['/assets/images/sideMenu/contract-rate-active.png', '/assets/images/sideMenu/contract-rate.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/spot-rate/create',
      '/airline/admin/spot-rate/upload-rates',
      '/airline/admin/spot-rate/list/view',
      '/airline/admin/spot-rate/edit/'],
    // children: [
    //   {
    //     id: 'createSpotRate',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createSpotRate',
    //     type: 'item',
    //     url: '/airline/admin/spot-rate/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'spotRates',
    //     title: 'List',
    //     messageId: 'sidebar.airline.spotRates',
    //     type: 'item',
    //     url: '/airline/admin/spot-rate/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Weight Range
  {
    id: 'weightRange',
    title: 'Weight Range',
    messageId: 'sidebar.weightRange',
    type: 'item',
    icon: ['/assets/images/sideMenu/weight-range-active.png', '/assets/images/sideMenu/weight-range.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/wieght-range/create'],
    // children: [
    //   {
    //     id: 'createWeightRange',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createWeightRange',
    //     type: 'item',
    //     url: '/airline/admin/wieght-range/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Surcharge
  {
    id: 'surCharge',
    title: 'Surcharge',
    messageId: 'sidebar.surCharge',
    type: 'item',
    icon: ['/assets/images/sideMenu/surcharge-active.png', '/assets/images/sideMenu/surcharge.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/sur-charge/create'],
    // children: [
    //   {
    //     id: 'createSurCharge',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.createSurCharge',
    //     type: 'item',
    //     url: '/airline/admin/sur-charge/create',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Agents
  {
    id: 'airlineAgents',
    title: 'Agents',
    messageId: 'sidebar.airlineAgents',
    type: 'item',
    icon: ['/assets/images/sideMenu/agents-active.png', '/assets/images/sideMenu/agents.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/agents/add',
      '/airline/admin/agents/list/view'],
    // children: [
    //   {
    //     id: 'addAgent',
    //     title: 'Create',
    //     messageId: 'sidebar.airline.addAgent',
    //     type: 'item',
    //     url: '/airline/admin/agents/add',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    //   {
    //     id: 'agentList',
    //     title: 'List',
    //     messageId: 'sidebar.airline.agentList',
    //     type: 'item',
    //     url: '/airline/admin/agents/list/view',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Key Management
  {
    id: 'assignKeyManagement',
    title: 'Key Management',
    messageId: 'sidebar.assignKeyManagement',
    type: 'item',
    icon: ['/assets/images/sideMenu/key-management-active.png', '/assets/images/sideMenu/key-management.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/assign-key',
      '/airline/admin/keymanagement/add/reassign/'],
    // children: [
    //   {
    //     id: 'assignKey',
    //     title: 'Assign Key',
    //     messageId: 'sidebar.assignKeyManagement.assignKey',
    //     type: 'item',
    //     url: '/airline/admin/assign-key',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Tracking
  // {
  //   id: 'trackingManagement',
  //   title: 'Tracking',
  //   messageId: 'sidebar.trackingManagement',
  //   type: 'collapse',
  //   icon: '/assets/images/sideMenu/tracking.png',
  //   role: ['ROLE_AIRLINE_ADMIN'],
  //   children: [],
  // },

  // Notifications
  {
    id: 'notification',
    title: 'Notifications',
    messageId: 'sidebar.notification',
    type: 'item',
    icon: ['/assets/images/sideMenu/notification-active.png', '/assets/images/sideMenu/notification.png'],
    role: ['ROLE_AIRLINE_ADMIN'],
    url: ['/airline/admin/notification'],
    // children: [
    //   {
    //     id: 'notification',
    //     title: 'Notifications',
    //     messageId: 'sidebar.notification',
    //     type: 'item',
    //     url: '/airline/admin/notification',
    //     icon: '/assets/images/sideMenu/sub-menu.png',
    //   },
    // ],
  },

  // Tutorial
  {
    id: 'ytTutorial',
    title: 'Tutorial',
    messageId: 'sidebar.ytTutorial',
    type: 'item',
    icon: ['/assets/images/sideMenu/youtube-active.png', '/assets/images/sideMenu/youtube.png'],
    url: ['/airline/admin/tutorial/view'],
    role: ['ROLE_AIRLINE_ADMIN'],
  },
];
